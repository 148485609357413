import $ from 'jquery'
import '../../libs/js/jquery.ihavecookies.min'
import get_data from '../tools/data'

function getCookieData (name) {
  /**
   * Takes a cookies name and searches all cookies for the specified cookie. If found it returns the value of the
   * cookie, otherwise it returns false.
   */
  let result = false
  const pairs = document.cookie.split('; ')
  $.each(pairs, function (index, value) {
    const parts = value.split('=')
    if (parts[0] === name) {
      result = parts[1]
      return false
    }
  })
  return result
}

export function submit_form_hooks () {
  const $ga_event_data = $('#ga-event-data')
  if ($ga_event_data.length) {
    const ga_event_data = JSON.parse($ga_event_data.text())
    if (ga_event_data) {
      window.ga_category = window.ga_category || ga_event_data.ga_category
      window.ga_action = window.ga_action || ga_event_data.ga_action
      window.ga_label = window.ga_label || ga_event_data.ga_label
    }
  }

  // Check if we're firing a GA4 Signup event, if so then we don't want to fire the GA4 event on form submit as we
  // manually do it in signup_confirmation.js to stop it firing upon invalid form completion or spam signups
  const ga_data = get_data('ga-data')
  let load_tc2_ga4 = ga_data.load_tc2_ga4

  if (!window.ga_category || !window.ga_action || load_tc2_ga4) {
    return
  }

  let ga_form_submit = false
  $('form').submit(function (e) {
    $('[type=submit]').not('.dont-disable').prop('disabled', true)
    if (!ga_form_submit) {
      e.preventDefault()

      const $form = $(this)
      if (getCookieData('_ga')) {
        // This will fire to both analytics IDs if both are present
        gtag('event', window.ga_action, {
          'event_category': window.ga_category,
          'event_label': window.ga_label
        })
      }
      ga_form_submit = true
      $form.submit()
    }
  })
}

function cookiesPopup () {
  /**
   * Creates a popup for the user to accept cookies if the cookieControlPrefs cookie doesn't already exist. Once the
   * user accepts their selection of cookies, we set the users respective consent options by updating the GA4
   * consent options.
   */
  $(document).ready(function () {
    let body = $('body')
    if (getCookieData('cookieControl') === 'true') {
      return
    } else {
      setTimeout(() => (body.append('<div class="overlay"></div>')), 100)
    }
    body.ihavecookies({
      title: 'We value your privacy',
      message: 'This website uses cookies to ensure you get the best experience on our website.',
      delay: 50,
      link: '/terms/#1-privacy-policy',
      uncheckBoxes: true,
      acceptBtnLabel: 'Accept all',
      advancedBtnLabel: 'Manage options',
      moreInfoLabel: 'View privacy policy.',
      cookieTypes: [
        {
          'type': 'Analytics',
          'value': 'analytics_storage',
          'descriptions': 'Cookies related to site visits and browser usage for our third-party applications.'
        },
      ],
      onAccept: () => {
        $('.overlay').remove()
        _set_ga4_consent_options('update')
      }
    })

    $(document).on('click', '#gdpr-cookie-advanced', function () {
      $('#gdpr-cookie-accept').text('Accept selected')
      $('#gdpr-cookietype-analytics_storage').prop('checked', true)
    })
  })
}

function _set_ga4_consent_options (type) {
  /**
   * Checks for cookieControlPrefs cookie and sets the default consent options to granted if the user has already
   * accepted the cookie policy. If the user doesn't have the cookieControlPrefs cookie then we set also set a
   * wait_for_update parameter (in ms) which allows the user time to accept cookies before we try pinging GA4.
   * More info here https://support.google.com/tagmanager/answer/10718549?hl=en#:~:text=Tag%20Manager%20consent%20types
   * @param type: 'default' or 'update' - default is used on page load and update is used when the user accepts cookies
   */
  var consentOptionsDefault = {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied',
  }

  if (getCookieData('cookieControlPrefs')) {
    let cookieControlPrefs =  decodeURIComponent(getCookieData('cookieControlPrefs'))
    Object.keys(consentOptionsDefault).forEach(option => {
      if (cookieControlPrefs.includes(option)) {
        consentOptionsDefault[option] = 'granted'
      }
    })
  } else {
    consentOptionsDefault.wait_for_update = 15000 // 15 seconds
  }

  gtag('consent', type, consentOptionsDefault)
}

function configure_ga () {
  /**
   * Configures GA4 using either our GA4 analytics key or the companies GA4 analytics key if they have one. Sets the
   * users consent options and sets up the config for the page.
   */
  const page_data = get_data('ga-data')
  const ga_data = Object.assign(page_data.ga_data, page_data.view_ga_data || {})

  window.dataLayer = window.dataLayer || []
  // eslint-disable-next-line no-undef
  function gtag () { dataLayer.push(arguments) }
  window.gtag = gtag

  // Consent needs to happen before anything else
  _set_ga4_consent_options('default')
  gtag('js', new Date())

  let analytics_config = {
    page_title: ga_data.page,
    transport_url: 'https://www.google-analytics.com',
  }
  const identity = ga_data.identity
  if (identity !== undefined) {
    analytics_config.user_id = identity
  }

  if (page_data.load_tc2_ga4) {
    cookiesPopup()
    gtag('config', window.sys.GA4_ANALYTICS_MEASUREMENT_ID, analytics_config)
    console.log('Config called with:', window.sys.ADWORDS_MEASUREMENT_ID)
    gtag('config', window.sys.ADWORDS_MEASUREMENT_ID, analytics_config)
  }

  if (ga_data.company_ga4_measurement_id) {
    // gtag will auto send to our GA4 property & companies GA4 property
    // cookiesPopup() - Can add in when we have a solution for making sure companies have a cookie policy in their t&c's
    gtag('config', ga_data.company_ga4_measurement_id, analytics_config)
  }

  gtag('set', {'dimension1': ga_data.role})
}

$(document).ready(function () {
  configure_ga()
  submit_form_hooks()
})
